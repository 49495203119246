import React from "react"

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"

import Layout from "../layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Img from "gatsby-image"

// Sectionals
import Hero from "../components/index/hero"
import Sectional1 from "../components/index/sectional-1"

const IndexPage = ({ data }) => {
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
    },
    property: {
      textDecoration: 'none',
      textAlign: 'center',
      color: theme.palette.text.primary,
    }
  }));
  const classes = useStyles();

  const propertiesEdges = data.allContentfulProperty.edges
  let propertyNodes = []

  propertiesEdges.map((edge, i) => (
    propertyNodes.push(
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Paper className={classes.paper}>
            <Link to={`/p/${edge.node.slug}/`} className={classes.property}>
            <Img fluid={edge.node.propertyImages[0].fluid} alt={edge.node.propertyImages[0].title} style={{minHeight: '272px'}}/>
            <Typography variant="body1"> {edge.node.propertyName} </Typography>
            </Link>
        </Paper>
      </Grid>
    )
  ))
  

  return(
    <Layout>
      <SEO title="Rent Like Royalty" ogImage="https://images.ctfassets.net/jld0bgdfw9eu/1J0BwTKIgCHLeTvtzlsa17/0e98478a454e437f9cfc01030c6a6d27/ogimage.png" />

      <Hero>
        <Container fixed align="center" style={{ paddingTop: '2rem' }}>
          <Typography variant="h1" style={{ marginBottom: '2rem' }}>
            Guests Wanted
          </Typography>
        </Container>
      </Hero>

      <Container fixed style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Typography variant="h2" align="center" style={{ marginBottom: '20px' }}>Rent Like Royalty</Typography>
        
        <div className={classes.root}>
          <Grid container spacing={3}>
            {propertyNodes}
          </Grid>
        </div>
      </Container>

      <Sectional1 />

      <Container fixed >
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
        >
          <Grid item xs={12} sm={7}>
            <Typography variant="h2" >Costa Del Sol</Typography>
            <Typography variant="body1" paragraph>Located just 8 miles (12.5km) south of downtown (San Miquel) within the hotel zone, Costa Del Sol is a luxury gated community with 26 villas style, private residences. Featuring immediate access to the ocean,  manicured landscapes, a full-size beachfront infinity pool, and private fishing dock, Costa del Sol offers a tranquil and secure experience for its guests. The pristine tropical waters accent this world class Carribean setting.</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
              <Image />
          </Grid>

        </Grid>
      </Container>

      <Sectional1 />

      <Container fixed >
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
        >

          <Grid item xs={12} sm={4}>
            <div style={{ maxWidth: `390px` }}>
              <Image />
            </div>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h2" >Explore Cozumel</Typography>
            <Typography variant="body1" paragraph>Situated off the eastern coast of Mexico's Yucatan Peninsula , the island of Cozumel is a municipality located within the state of Quintana Roos. Cozumel is located approximately 12 miles south of the mainland (Cancun) and constitutes Mexico's largest Carribean island. It is  separated from adjacent Playa del Carmen by the Cozumel Channel. The majority of the population in Cozumel is located on the western shore of the island in the city of San Miguel.</Typography>
            <Typography variant="body1" paragraph>Surrounded by a diverse ecosystem of coral reefs, Cozumel's economy is driven by the island's aquatic setting affording  some of the best scuba diving and charter fishing in the world. Other water activities include para-sailing, kitesurfing, and snorkeling complimenting a tropical climate that allows for year round excursions. Over the last two decades, the city of San Miguel has grown to accommodate those seeking to make Cozumel their vacation destination.</Typography>
          </Grid>

        </Grid>
      </Container>

      <Sectional1 />

    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allContentfulProperty(limit: 8) {
      edges {
        node {
          id
          slug
          propertyName
          maxGuests
          propertyImages { 
            title
            file {
              url
              fileName
              contentType
            }
            resize (width: 362, height: 272) {
              src
            }
            fluid(maxWidth: 362){
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
